//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, Fragment, useEffect, useRef, useLayoutEffect } from 'react';
import { isNumber } from 'lodash';
//Core
import { Stack } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";

//Icons
import Equalizer from '@material-ui/icons/Equalizer';
import Autorenew from '@material-ui/icons/Autorenew';
import CheckBoxFilled from '@material-ui/icons/CheckBox';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Divider from '@material-ui/core/Divider';
//Common
import { isProductPackaged } from './utils/isProductPackaged';
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import CheckResponse from "../Flight/FlightSelected/Functions/CheckResponse";
import { useGetFlightSellingPrice } from './utils/getFlightSellingPrice';
import { useGetTaxesPrice } from './utils/getTaxesPrice';
//Components
import CartPriceVariationDialog from './CartPriceVariationDialog';
import { CartConstructionProductsHeader } from "./CartConstructionProductsHeader";
//Actions
import { SetIsBooking } from '../../Actions/Booking';
import { EditItemToBook } from '../../Actions/Cart';
import { EditTravelerGroups, SetDataTrip } from '../../Actions/Trip';
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';


const useStyles = makeStyles({
    stickyPaper: {
        //position: 'sticky',
        //top: 94,
        borderRadius: 16,
        padding: 16,
        maxWidth: '250px',
        margin: 'auto'
    },
    refreshButton: {
        padding: 8,
        marginTop: -9,
        marginRight: -9
    },
    marginToggle: {
        cursor: 'pointer'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    blueButtonContainer: {
        marginBottom: 4,
        marginTop: -4
    },
    blueButton: {
        border: '1px solid #76B6C2',
        backgroundColor: 'white',
        color: '#76B6C2'
    },
    blueButtonFilled: {
        backgroundColor: '#76B6C2',
        color: 'white'
    },
    orangeButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    cancelAll: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllCancel: {
        backgroundColor: 'white',
        border: '1px solid #E6592F',
        color: '#E6592F'
    },
    cancelAllWrapper: {
        position: 'relative'
    },
    cancelAllRunning: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -16
    },
    selector: {
        marginBottom: 4
    },
    checkBoxLabel: {
        marginBottom: 0
    },
    checked: {
        fill: 'black'
    },
    globalPriceWarningIcon: {
        verticalAlign: 'text-bottom',
        marginLeft: 6
    },
    signedPrice: {
        fontStyle: "italic",
        fontSize: 14,
        color: "gray",
        fontWeight: "normal"
    },
    orangeSwitchLabel: {
        color: '#E6592F',
        textTransform: 'uppercase',
        fontSize: 14
    },
    orangeBackground: {
        '& .mui-jss-MuiSwitch-track': {
            backgroundColor: '#E6592F',
            opacity: "13%"
        }
    }
});

const OrangeSwitch = withStyles({
    switchBase: {
        "color": '#E6592F',
        '&$checked': {
            color: '#E6592F'
        },
        '&$checked + $track': {
            backgroundColor: '#E6592F'
        }
    },
    checked: {},
    track: {}
})(Switch);

const CartCard = ({ router, stickedActions }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [spinRefresh, setSpinRefresh] = useState(false);
    const [spinCancelAll, setSpinCancelAll] = useState(false);
    const [spinDownload, setSpinDownload] = useState(false);
    const user = useSelector(state => state.user.user);
    const locales = useSelector(state => state.user.locales);
    const step = useSelector(state => state.cart.step);
    const trip = useSelector(state => state.trip.data_trip);
    const currency = useSelector(state => state.trip.currency);
    const see_margin = useSelector(state => state.cart.see_margin);
    const language = useSelector(state => state.header.tmp_language);
    const currency_list = useSelector(state => state.base.currency_list);
    const quotation_target = useSelector(state => state.cart.quotation_target);
    const quotation_type = useSelector(state => state.cart.quotation_type);
    const quotation_category_price_detail = useSelector(state => state.cart.quotation_category_price_detail);
    const quotation_product_price_detail = useSelector(state => state.cart.quotation_product_price_detail);
    const quotation_practical_information = useSelector(state => state.cart.quotation_practical_information);
    const quotation_without_prices = useSelector(state => state.cart.quotation_without_prices);
    const quotation_target_language = useSelector(state => state.cart.quotation_target_language);
    const show_cancelled_products = useSelector(state => state.cart.show_cancelled_products);
    const quotation_cgv = useSelector(state => state.cart.quotation_cgv);
    const to_book = useSelector(state => state.cart.to_book);
    const trip_reference = useSelector(state => state.trip.trip_reference);
    const end_date = useSelector(state => state.trip.end_date);
    const pois = useSelector(state => state.poi.cart);
    const flights = useSelector(state => state.flight.cart);
    const currentLocale = useSelector((state) => state.header.language);
    const cars = useSelector(state => state.cars_search.cart);
    const transfers = useSelector(state => state.transfers.cart);
    const accommodations = useSelector(state => state.accommodation.sort_cart);
    const manual_products = useSelector(state => state.cart.manual_item_list);
    const assistances = useSelector(state => state.cart.assistance_cart);
    const traveler_groups = useSelector(state => state.trip.traveler_groups);
    const total_cart_cost = useSelector(state => state.trip.total_cost);
    const all_data = useSelector(state => state.trip.all_data);
    const filters_booked_items = useSelector(state => state.cart.filters_booked_items);
    const filters_non_booked_items = useSelector(state => state.cart.filters_non_booked_items);
    const filters_masked_items = useSelector(state => state.cart.filters_masked_items);
    const filters_visible_items = useSelector(state => state.cart.filters_visible_items);
    const filters_cancellable_items = useSelector(state => state.cart.filters_cancellable_items);
    const filters_non_cancellable_items = useSelector(state => state.cart.filters_non_cancellable_items);
    const hasStackedItems = useSelector(state => state.cart.hasStackedItems);
    const tva_value = useSelector(state => state.cart.tva_value);

    const [margin, setMargin] = useState(null);
    const [surcom, setSurcom] = useState(null);
    const [totalCost, setTotalCost] = useState([]);
    const [flightTaxes, setFlightTaxes] = useState(0);
    const [nbItems, setNbItems] = useState(0);
    const [totalCartCost, setTotalCartCost] = useState(0);
    const [hasBookedItems, setHasBookedItems] = useState(0);
    const [cancelAllModal, setCancelAllModal] = useState(false);
    const [editAdvancePayment, setEditAdvancePayment] = useState(false);
    const [advancePayment, setAdvancePayment] = useState(0);
    const [newAdvancePayment, setNewAdvancePayment] = useState(0);
    const [paymentDone, setPaymentDone] = useState(0);
    const [openPriceVariation, setOpenPriceVariation] = useState(false);
    const [diffPrice, setDiffPrice] = useState(0);
    const [newProduct, setNewProduct] = useState([]);
    const [modifiedProduct, setModifiedProduct] = useState([]);
    const [tripTva, setTripTva] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [getFlightSellingPrice] = useGetFlightSellingPrice();
    const getTaxesPrice = useGetTaxesPrice();

    const quotation_code = JSON.parse(localStorage.getItem('config')).quotation_code;

    const isDisabled = ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', "touristforum"].includes(quotation_code) ? (trip !== null && trip.status_contract === "CONFIRMED" && trip.status_modification === "FIXED_PV" ? true : false) : false;
    const onToggleMargin = () => {
        dispatch({ type: 'CART_TOGGLE_MARGIN' });
    };
    const onShowCancelledProducts = () => {
        dispatch({ type: 'CART_TOGGLE_SHOW_CANCELLED_PRODUCTS' });
    };
    const onToggleCancelAll = () => {
        setCancelAllModal(!cancelAllModal);
    };
    const onCancelAllBooking = () => {
        const { headers } = CheckBeforeRequest();
        let cancelled_pois = [];
        setSpinCancelAll(true);
        setCancelAllModal(false);
        pois.map(poi => {
            if (poi.booking_process_state === 'NOTHING' && poi.booking_status !== null && (poi.booking_status.status_booking === 'CONFIRMED' || poi.booking_status.status_booking === 'PENDING' || poi.booking_status.status_booking === 'WAITING')) {
                cancelled_pois.push(poi.id);
            }
        });
        if (cancelled_pois.length > 0) {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${pois[0].product_type}`,
                data: {
                    items: cancelled_pois
                }
            }).then(response => {
                console.log('response.data : ', response.data);
                enqueueSnackbar(cancelled_pois.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
                dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
                dispatch({ type: 'POI_UPDATE_CART', payload: response.data });
                setSpinCancelAll(false);
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(cancelled_pois.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
                setSpinCancelAll(false);
            });
        }
        let cancelled_flights = [];
        flights.map(flight => {
            if (flight.booking_process_state === 'NOTHING' && flight.booking_status !== null && (flight.booking_status.status_booking === 'CONFIRMED' || flight.booking_status.status_booking === 'PENDING' || flight.booking_status.status_booking === 'WAITING')) {
                cancelled_flights.push(flight.id);
            }
        });
        if (cancelled_flights.length > 0) {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${flights[0].product_type}`,
                data: {
                    items: cancelled_flights
                }
            }).then(response => {
                console.log('response.data : ', response.data);
                enqueueSnackbar(cancelled_flights.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
                dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
                dispatch({ type: 'FLIGHT_UPDATE_CART', payload: response.data });
                setSpinCancelAll(false);
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(cancelled_flights.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
                setSpinCancelAll(false);
            });
        }
        let cancelled_cars = [];
        cars.map(car => {
            if (car.booking_process_state === 'NOTHING' && car.booking_status !== null && (car.booking_status.status_booking === 'CONFIRMED' || car.booking_status.status_booking === 'PENDING' || car.booking_status.status_booking === 'WAITING')) {
                cancelled_cars.push(car.id);
            }
        });
        if (cancelled_cars.length > 0) {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${cars[0].product_type}`,
                data: {
                    items: cancelled_cars
                }
            }).then(response => {
                console.log('response.data : ', response.data);
                enqueueSnackbar(cancelled_cars.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
                dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
                dispatch({ type: 'CARS_UPDATE_CART', payload: response.data });
                setSpinCancelAll(false);
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(cancelled_cars.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
                setSpinCancelAll(false);
            });
        }
        let cancelled_transfers = [];
        transfers.map(transfer => {
            if (transfer.booking_process_state === 'NOTHING' && transfer.booking_status !== null && (transfer.booking_status.status_booking === 'CONFIRMED' || transfer.booking_status.status_booking === 'PENDING' || transfer.booking_status.status_booking === 'WAITING')) {
                cancelled_transfers.push(transfer.id);
            }
        });
        if (cancelled_transfers.length > 0) {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${transfers[0].product_type}`,
                data: {
                    items: cancelled_transfers
                }
            }).then(response => {
                console.log('response.data : ', response.data);
                enqueueSnackbar(cancelled_transfers.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
                dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
                dispatch({ type: 'TRANSFERS_UPDATE_CART', payload: response.data });
                setSpinCancelAll(false);
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(cancelled_transfers.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
                setSpinCancelAll(false);
            });
        }
        let cancelled_rooms = [];
        accommodations.map(accommodation => {
            accommodation.rooms.map(room => {
                if (room.booking_process_state === 'NOTHING' && room.booking_status !== null && (room.booking_status.status_booking === 'CONFIRMED' || room.booking_status.status_booking === 'PENDING' || room.booking_status.status_booking === 'WAITING')) {
                    cancelled_rooms.push(room.id);
                }
            });
        });
        if (cancelled_rooms.length > 0) {
            axios({
                method: 'DELETE',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/booking/destroy/?product_types=${accommodations[0].product_type}`,
                data: {
                    items: cancelled_rooms
                }
            }).then(response => {
                console.log('response.data : ', response.data);
                enqueueSnackbar(cancelled_rooms.length > 1 ? t('cart-material.bookings-deleted') : t('cart-material.booking-deleted'), { variant: 'success' });
                dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: null });
                dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                setSpinCancelAll(false);
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(cancelled_rooms.length > 1 ? t('cart-material.bookings-not-deleted') : t('cart-material.booking-not-deleted'), { variant: 'danger' });
                setSpinCancelAll(false);
            });
        }
    };
    const onRefreshPrice = () => {
        const { headers } = CheckBeforeRequest();
        dispatch(SetIsBooking(false));
        setSpinRefresh(true);
        axios({
            method: 'GET',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/refresh_prices/?token=${GetCookie('trip_token')}`
        }).then(response => {
            console.log('response.data : ', response.data);
            dispatch({ type: 'CART_REFRESH_PRICE', payload: response.data });
            //enqueueSnackbar(t('cart-material.refresh-price-available'), {variant: 'success'});
            setSpinRefresh(false);
        }).catch(error => {
            console.log(error);
            setSpinRefresh(false);
            enqueueSnackbar(t('cart-material.refresh-price-not-available'), { variant: 'warning' });
        });
    };
    const onSetStep = value => () => {
        dispatch({ type: 'CART_SET_STEP', payload: { step: value } });
    };
    const onGoToBooking = () => {
        router.push(`/${window.url_name}/apps/booking`);
    };
    const getToBookProducts = () => {
        let tmp_to_book = [];
        pois.map(poi => {
            if (poi.is_available && !poi.is_optional && (poi.booking_status === null || poi.booking_status.status_booking === 'ERROR') && poi.booking_process_state === 'NOTHING' && poi.poi_type === false) {
                tmp_to_book.push(poi);
            }
        });
        assistances.map(assistance => {
            if (assistance.is_available && !assistance.is_optional && (assistance.booking_status === null || assistance.booking_status.status_booking === 'ERROR') && assistance.booking_process_state === 'NOTHING') {
                tmp_to_book.push(assistance);
            }
        });
        flights.map(flight => {
            if (flight.is_available && !flight.is_optional && (flight.booking_status === null || flight.booking_status.status_booking === 'ERROR') && flight.booking_process_state === 'NOTHING') {
                tmp_to_book.push(flight);
            }
        });
        cars.map(car => {
            if (car.is_available && !car.is_optional && (car.booking_status === null || car.booking_status.status_booking === 'ERROR') && car.booking_process_state === 'NOTHING') {
                tmp_to_book.push(car);
            }
        });
        transfers.map(transfer => {
            if (transfer.is_available && !transfer.is_optional && (transfer.booking_status === null || transfer.booking_status.status_booking === 'ERROR') && transfer.booking_process_state === 'NOTHING') {
                tmp_to_book.push(transfer);
            }
        });
        accommodations.map(accommodation => {
            accommodation.rooms.map(room => {
                if (room.is_available && !room.is_optional && (room.booking_status === null || room.booking_status.status_booking === 'ERROR') && room.booking_process_state === 'NOTHING') {
                    tmp_to_book.push(room);
                }
            });
        });
        if (user.client_full.type === 2) {
            manual_products.map(manual_product => {
                if ([0, 2, 4, 6, 12].includes(manual_product.product_type) && manual_product.is_available && !manual_product.is_optional && (manual_product.booking_status === null || manual_product.booking_status.status_booking === 'ERROR') && manual_product.booking_process_state === 'NOTHING') {
                    let temp_manual_product = Object.assign({}, manual_product);
                    temp_manual_product.is_manual = true;
                    tmp_to_book.push(temp_manual_product);
                }
            });
        }
        tmp_to_book = tmp_to_book.filter((item) => {
            if (filters_booked_items && !filters_non_booked_items) {
                return (item.booking_status !== null && item.booking_status.status_booking === "CONFIRMED");
            } else if (!filters_booked_items && filters_non_booked_items) {
                return (item.booking_status === null || (item.booking_status !== null && item.booking_status.status_booking !== "CONFIRMED"));
            }
            return item;
        }).filter((item) => {
            if (filters_masked_items && !filters_visible_items) {
                return (item.is_hidden_for_traveler);
            } else if (!filters_masked_items && filters_visible_items) {
                return (!item.is_hidden_for_traveler);
            }
            return item;
        }).filter((item) => {
            if (filters_cancellable_items && !filters_non_cancellable_items) {
                return (item.is_cancellable);
            } else if (!filters_cancellable_items && filters_non_cancellable_items) {
                return (!item.is_cancellable);
            }
            return item;
        });
        return tmp_to_book;
    };
    const onSelectAll = () => {
        let tmp_to_book = getToBookProducts();
        console.log('tmp_to_book:', tmp_to_book);
        dispatch({ type: 'CART_SET_TO_BOOK', payload: tmp_to_book });
    };
    const onDeselectAll = () => {
        dispatch({ type: 'CART_SET_TO_BOOK', payload: [] });
    };
    const handleSelectChange = (event) => {
        if (event.target.checked) {
            onSelectAll();
        } else {
            onDeselectAll();
        }
        setSelectAll(event.target.checked);
    };
    const onQuotationTargetChange = event => {
        dispatch({ type: 'CART_SET_QUOTATION_TARGET', payload: event.target.value });
    };
    const onQuotationTypeChange = event => {
        dispatch({ type: 'CART_SET_QUOTATION_TYPE', payload: event.target.value });
    };
    const onCategoryPriceDetailToggle = () => {
        dispatch({ type: 'CART_TOGGLE_QUOTATION_CATEGORY_PRICE_DETAIL' });
    };
    const onProductPriceDetailToggle = () => {
        dispatch({ type: 'CART_TOGGLE_QUOTATION_PRODUCT_PRICE_DETAIL' });
    };
    const onPracticalInformationToggle = () => {
        dispatch({ type: 'CART_TOGGLE_QUOTATION_PRACTICAL_INFORMATION' });
    };
    const onGenerateWithoutPricesToggle = () => {
        dispatch({ type: 'CART_TOGGLE_QUOTATION_WITHOUT_PRICES'});
    };
    const onCgvToggle = () => {
        dispatch({ type: 'CART_TOGGLE_QUOTATION_CGV' });
    };
    const getIndexNbForVersion = (array, nb) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === nb) {
                return (i + 1);
            }
        }
        return 1;
    };
    const onQuotationTargetLanguageChange = (event) => {
        dispatch({ type: 'CART_SET_QUOTATION_TARGET_LANGUAGE', payload: event.target.value });
    };
    const downloadQuotation = () => {
        const { headers } = CheckBeforeRequest();
        let index_version = all_data.data.findIndex(data => data.id === trip.id);
        let url = `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/quotation/?preview=n&generate_for_target=${(quotation_target === "TO" || quotation_target === 'COMMISSIONED') ? 'n' : 'y'}&generate_type=${quotation_type}${quotation_target === "COMMISSIONED" ? '&generate_without_agency_margin=y' : ''}&price_by_category=${quotation_category_price_detail ? 'y' : 'n'}&price_by_product=${quotation_product_price_detail ? 'y' : 'n'}&generate_with_practical_information=${quotation_practical_information ? 'y' : 'n'}&generate_with_cgv=${quotation_cgv ? 'y' : 'n'}&generate_without_prices=${quotation_without_prices ? 'y' : 'n'}`;
        setSpinDownload(true);
        headers['Accept-Language'] = quotation_target_language;
        axios({
            method: 'GET',
            headers: headers,
            url: url,
            responseType: 'blob'
        }).then((response) => {
            setSpinDownload(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            if (quotation_code === 'verdie') {
                link.setAttribute('download', `VOTRE PROJET DE VOYAGE_${trip.destination.data.international_name}_${all_data.name}_v${index_version + 1}.pdf`);
            } else {
                link.setAttribute('download', `${t("menu.trip_list.quotation_title")}${all_data.trip_reference} ${t("menu.trip_list.nb_version")}${index_version + 1}.pdf`);
            }
            document.body.appendChild(link);
            link.click();
        }).catch(() => {
            setSpinDownload(false);
        });
        //let a = document.createElement("a");
        //a.href = url;
        //console.log('before');
        //a.addEventListener('click', (e) => {
        //console.log('event : ', e);
        //});
        //a.dispatchEvent(new MouseEvent('click'));
        //a.onload = () => {
        //console.log('ended');
        //};
        //console.log('after');
    };
    const priceToDisplay = price => {
        return {
            cost: parseFloat(price.selling_price),
            currency: currency_list.find(currency => currency.id === price.selling_currency)
        };
    };
    const getPrice = (prices, is_tva = true) => {
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            if (is_tva) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].is_tva) {
                        return priceToDisplay(prices[i]);
                    }
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_tva) {
                    return priceToDisplay(prices[i]);
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    return priceToDisplay(prices[i]);
                }
            }
        }
        return {
            cost: 0,
            currency: currency
        };
    };
    const getMargin = (tmp_margin, tmp_surcom, prices, id) => {
        if (user.client_full.type === 1 || user.client_full.type === 3 || quotation_code === 'verdie') {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].is_surcom) {
                    tmp_surcom.purchase += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_surcom.selling += parseFloat(prices[i].selling_price);
                    tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_surcom.percent = parseFloat(prices[i].percentage_between);
                    break;
                }
            }
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].master_price) {
                    tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i].selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_margin.percent = parseFloat(prices[i].percentage_between);
                    tmp_margin.ids.push(id);
                    break;
                }
            }
            if (!tmp_margin.ids.includes(id)) {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i].owner === user.client) {
                        tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i].selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                        tmp_margin.percent = parseFloat(prices[i].percentage_between);
                        tmp_margin.ids.push(id);
                        break;
                    }
                }
            }
        } else {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].owner === user.client) {
                    tmp_margin.purchase += parseFloat(prices[i].purchase_price);
                    tmp_margin.purchase_converted += parseFloat(prices[i].purchase_price) * parseFloat(prices[i].factor_used);
                    tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i].purchase_currency);
                    tmp_margin.selling += parseFloat(prices[i].selling_price);
                    tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i].selling_currency);
                    tmp_margin.percent = parseFloat(prices[i].percentage_between);
                    tmp_margin.id = prices[i].id;
                    break;
                }
            }
        }
    };
    const onToggleAdvancePayment = () => {
        setEditAdvancePayment(!editAdvancePayment);
    };
    const onChangeAdvancePayment = (e) => {
        setNewAdvancePayment(e.target.value);
    };
    const onToggleCancelAdvancePayment = () => {
        setNewAdvancePayment(advancePayment);
        setEditAdvancePayment(false);
    };
    const onToggleValidateAdvancePayment = () => {
        const { headers } = CheckBeforeRequest();
        let request = {
            expected_deposit: parseFloat(newAdvancePayment),
            has_manual_expected_deposit: true
        };
        axios({
            method: "PATCH",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
            data: request
        }).then(function (response) {
            onToggleAdvancePayment();
            dispatch(SetDataTrip(response.data));
        }).catch(function (error) {
            console.log("data trip refresh error", error);
        });
    };
    const handlePriceVariation = () => {
        setOpenPriceVariation(!openPriceVariation);
    };

    useEffect(() => {
        dispatch({
            type: 'CART_SET_QUOTATION_TARGET_LANGUAGE',
            payload: currentLocale
        });
    }, [currentLocale]);

    useEffect(() => {
        if (step === 4) {
            let { headers, pass_check } = CheckBeforeRequest();
            traveler_groups.map((group, group_index) => {
                if (group.lead_traveler_info !== null && group.lead_traveler_info.first_name === t("global.first_name") && group.lead_traveler_info.last_name === t("global.last_name")) {
                    let request = {
                        lead_traveler: null
                    };
                    if (pass_check) {
                        axios({
                            method: "PATCH",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/travelers-group/${group.id}/`,
                            data: request
                        }).then(function (response) {
                            dispatch(EditTravelerGroups(response.data, group_index));
                            to_book.map((product) => {
                                if (product.group_passenger.id === group.id) {
                                    let temp_product = Object.assign({}, product);
                                    temp_product.group_passenger = response.data;
                                    dispatch(EditItemToBook(temp_product));
                                }
                            });
                        }).catch(function (error) {
                            console.log("travelers group refresh error", error);
                        });
                    }
                }
            });
        }
        if (['visiteurs', 'volonline', "gattinoni", 'tropicalementvotre'].includes(quotation_code) && !quotation_practical_information && step === 3) {
            onPracticalInformationToggle();
        }
        if (['tropicalementvotre'].includes(quotation_code) && !quotation_practical_information && step === 3) {
            dispatch({ type: 'CART_SET_QUOTATION_TARGET', payload: "CLIENT" });
        }
    }, [step]);
    useEffect(() => {
        let total_cost = [];
        let total_manual_products = [];
        let total_accommodations = [];
        let total_cars = [];
        let total_pois = [];
        let total_assistances = [];
        let total_transfers = [];
        let total_flights = [];
        let flight_taxes = 0;
        let nb_items = 0;
        let has_booked_items = 0;
        let has_not_cancellable_items = 0;
        let custom_cost = false;
        let tmp_margin = {
            purchase: 0,
            purchase_converted: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null,
            rate_type: 'PER',
            ids: []
        };
        let tmp_surcom = {
            purchase: 0,
            selling: 0,
            value: 0,
            percent: 0,
            currency: null
        };
        let new_product = [];
        let modified_product = [];
        let advance_payment = 0;
        let payment_done = 0;
        let tva = 0;
        let adjustment_product = manual_products.find(product => product.product_type === 20);
        let status_contract_confirmed_date = adjustment_product === undefined ? moment(trip.status_contract_confirmed_date).local() : moment(adjustment_product.modified_date);
        if (trip.prices_flight.length > 0) {
            custom_cost = !hasStackedItems;
            let price = getPrice(trip.prices_flight);
            getMargin(tmp_margin, tmp_surcom, trip.prices_flight, `${trip.id}-flight`);
            let matched = false;
            if (trip.flight_taxes !== null) {
                flight_taxes = parseFloat(trip.flight_taxes);
            }
            total_cost.map(currency_cost => {
                if (currency_cost.currency.id === price.currency.id) {
                    matched = true;
                    currency_cost.cost += price.cost + (trip.flight_taxes !== null ? parseFloat(trip.flight_taxes) : 0);
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost + (trip.flight_taxes !== null ? parseFloat(trip.flight_taxes) : 0),
                    currency: price.currency
                });
            }
        }
        if (trip.prices_terrestrial.length > 0) {
            custom_cost = !hasStackedItems;
            let price = getPrice(trip.prices_terrestrial);
            getMargin(tmp_margin, tmp_surcom, trip.prices_terrestrial, `${trip.id}-terrestrial`);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency.id === price.currency.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }
        accommodations.map(accommodation => {
            accommodation.rooms.map(room => {
                if (room.booking_process_state === 'NOTHING' && room.booking_status !== null && room.booking_status.status_booking === 'CONFIRMED') {
                    has_booked_items++;
                }
                if ((room.is_available || (room.booking_status !== null && room.booking_status.status_booking === 'CONFIRMED')) && room.is_displayed && !room.is_hidden_for_traveler && !room.is_optional && (room.booking_status === null || (room.booking_process_state !== 'CANCEL' && room.booking_status.status_booking !== 'CANCELLED'))) {
                    nb_items++;
                    let tva_price = room.prices.find((price) => price.is_tva);
                    if (tva_price !== undefined) {
                        tva += (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                        if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: room.hotel[0].name + " - " + room.description,
                                price: {
                                    cost: tva_price,
                                    adjustment: room.prices.find((price) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = room.prices.find((price) => price.master_price);
                        if (tmp_price !== undefined) {
                            if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                                modified_product.push({
                                    label: room.hotel[0].name + " - " + room.description,
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                    if (!room.is_cancellable) {
                        has_not_cancellable_items++;
                    }
                    if (!isProductPackaged({ product: room, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                        let price = getPrice(room.prices);
                        getMargin(tmp_margin, tmp_surcom, room.prices, room.id);
                        let matched = false;
                        total_cost.map(currency_cost => {
                            if (currency_cost.currency.id === price.currency.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                            }
                        });
                        if (!matched) {
                            total_cost.push({
                                cost: price.cost,
                                currency: price.currency
                            });
                        }
                        matched = false;
                        total_accommodations.map(currency_cost => {
                            if (currency_cost.currency.id === price.currency.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                            }
                        });
                        if (!matched) {
                            total_accommodations.push({
                                cost: price.cost,
                                currency: price.currency
                            });
                        }
                    }
                }
            });
        });
        cars.map(car => {
            if (car.booking_process_state === 'NOTHING' && car.booking_status !== null && car.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if ((car.is_available || (car.booking_status !== null && car.booking_status.status_booking === 'CONFIRMED')) && car.is_displayed && !car.is_hidden_for_traveler && !car.is_optional && (car.booking_status === null || (car.booking_process_state !== 'CANCEL' && car.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                //if (!car.is_cancellable) {
                //has_not_cancellable_items++;
                //}
                let tva_price = car.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price !== undefined) {
                    calc_tva = (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                    tva += calc_tva;
                    if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: car.name,
                            price: {
                                cost: tva_price,
                                adjustment: car.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = car.prices.find((price) => price.master_price);
                    if (tmp_price !== undefined) {
                        if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: car.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (!isProductPackaged({ product: car, stackInfos: trip?.stack_info ?? null }) && !custom_cost && !car.catalog_price) {
                    let price = getPrice(car.prices);
                    getMargin(tmp_margin, tmp_surcom, car.prices, car.id);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_cars.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cars.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        manual_products.map(manual_product => {
            if ((manual_product.is_available || (manual_product.booking_status !== null && manual_product.booking_status.status_booking === 'CONFIRMED')) && (manual_product.is_displayed || ['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code)) && !manual_product.is_hidden_for_traveler && !manual_product.is_optional && (manual_product.booking_status === null || (manual_product.booking_process_state !== 'CANCEL' && manual_product.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                //if ((moment.utc(manual_product.created_date).isAfter(moment.utc(status_contract_confirmed_date)) || moment.utc(manual_product.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                let tva_price = manual_product.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price !== undefined) {
                    calc_tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    tva += calc_tva;
                    if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date))) && manual_product.is_displayed) {
                        modified_product.push({
                            label: manual_product.name,
                            price: {
                                cost: tva_price,
                                adjustment: manual_product.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = manual_product.prices.find((price) => price.master_price);
                    if (tmp_price !== undefined) {
                        if (manual_product.product_type !== 18 && manual_product.product_type !== 20 && (moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: manual_product.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (!manual_product.no_refund && manual_product.cancel_condition_text !== null) {
                    has_not_cancellable_items++;
                }
                if (!isProductPackaged({ product: manual_product, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    if (manual_product.taxes !== null) {
                        flight_taxes += getTaxesPrice(manual_product.taxes);
                    }
                    let price = getPrice(manual_product.prices);
                    getMargin(tmp_margin, tmp_surcom, manual_product.prices, manual_product.id);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                            if (manual_product.taxes !== null) {
                                currency_cost.cost += getTaxesPrice(manual_product.taxes);
                            }
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost + (manual_product.taxes !== null ? getTaxesPrice(manual_product.taxes) : 0),
                            currency: price.currency
                        });
                    }
                    matched = false;
                    switch (manual_product.product_type) {
                        case 0:
                        case 7:
                            total_accommodations.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                }
                            });
                            if (!matched) {
                                total_accommodations.push({
                                    cost: price.cost,
                                    currency: price.currency
                                });
                            }
                            break;
                        case 2:
                            total_cars.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                }
                            });
                            if (!matched) {
                                total_cars.push({
                                    cost: price.cost,
                                    currency: price.currency
                                });
                            }
                            break;
                        case 4:
                        case 13:
                            total_transfers.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                }
                            });
                            if (!matched) {
                                total_transfers.push({
                                    cost: price.cost,
                                    currency: price.currency
                                });
                            }
                            break;
                        case 6:
                            let master_price = manual_product.prices.find((price) => price.master_price);
                            total_flights.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost + (getTaxesPrice(manual_product.taxes ?? '0') * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1));
                                }
                            });
                            if (!matched) {
                                total_flights.push({
                                    cost: price.cost + (getTaxesPrice(manual_product.taxes ?? '0') * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)),
                                    currency: price.currency
                                });
                            }
                            break;
                        case 8:
                        case 19:
                            total_assistances.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                }
                            });
                            if (!matched) {
                                total_assistances.push({
                                    cost: price.cost,
                                    currency: price.currency
                                });
                            }
                            break;
                        case 12:
                            total_pois.map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                }
                            });
                            if (!matched) {
                                total_pois.push({
                                    cost: price.cost,
                                    currency: price.currency
                                });
                            }
                            break;
                        default:
                            total_manual_products.filter((item) => {
                                return !!item.auto_product === !!manual_product.auto_product;
                            }).map(currency_cost => {
                                if (currency_cost.currency.id === price.currency.id) {
                                    matched = true;
                                    currency_cost.cost += price.cost;
                                    if (manual_product.taxes !== null) {
                                        currency_cost.cost += getTaxesPrice(manual_product.taxes);
                                    }
                                }
                            });
                            if (!matched) {
                                total_manual_products.push({
                                    cost: price.cost + (manual_product.taxes !== null ? getTaxesPrice(manual_product.taxes) : 0),
                                    currency: price.currency,
                                    auto_product: !!manual_product.auto_product
                                });
                            }
                            break;
                    }
                    /*if ((user.client_full.type === 2 && manual_product.creator.client.type !== 2) || ['cercledesvoyages'].includes(quotation_code)) {
                        switch (manual_product.product_type) {
                            case 0:
                            case 7:
                                total_accommodations.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_accommodations.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 2:
                                total_cars.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_cars.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 4:
                                total_transfers.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_transfers.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 6:
                                let master_price = manual_product.prices.find((price) => price.master_price);
                                total_flights.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost + (getTaxesPrice(manual_product.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1));
                                    }
                                });
                                if (!matched) {
                                    total_flights.push({
                                        cost: price.cost + (getTaxesPrice(manual_product.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1)),
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 8:
                                total_assistances.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_assistances.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            case 12:
                                total_pois.map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                    }
                                });
                                if (!matched) {
                                    total_pois.push({
                                        cost: price.cost,
                                        currency: price.currency
                                    });
                                }
                                break;
                            default:
                                total_manual_products.filter((item) => {
                                    return item.auto_product === manual_product.auto_product;
                                }).map(currency_cost => {
                                    if (currency_cost.currency.id === price.currency.id) {
                                        matched = true;
                                        currency_cost.cost += price.cost;
                                        if (manual_product.taxes !== null) {
                                            currency_cost.cost += getTaxesPrice(manual_product.taxes);
                                        }
                                    }
                                });
                                if (!matched) {
                                    total_manual_products.push({
                                        cost: price.cost + (manual_product.taxes !== null ? getTaxesPrice(manual_product.taxes) : 0),
                                        currency: price.currency,
                                        auto_product: manual_product.auto_product
                                    });
                                }
                                break;
                        }
                    } else {
                        total_manual_products.filter((item) => {
                            return item.auto_product === manual_product.auto_product;
                        }).map(currency_cost => {
                            if (currency_cost.currency.id === price.currency.id) {
                                matched = true;
                                currency_cost.cost += price.cost;
                                if (manual_product.taxes !== null) {
                                    currency_cost.cost += getTaxesPrice(manual_product.taxes);
                                }
                            }
                        });
                        if (!matched) {
                            total_manual_products.push({
                                cost: price.cost + (manual_product.taxes !== null ? getTaxesPrice(manual_product.taxes) : 0),
                                currency: price.currency,
                                auto_product: manual_product.auto_product
                            });
                        }
                    } */
                }
            }
        });
        pois.map(poi => {
            if (poi.booking_process_state === 'NOTHING' && poi.booking_status !== null && poi.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if ((poi.is_available || (poi.booking_status !== null && poi.booking_status.status_booking === 'CONFIRMED')) && poi.is_displayed && !poi.is_hidden_for_traveler && !poi.is_optional && (poi.booking_status === null || (poi.booking_process_state !== 'CANCEL' && poi.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                let tva_price = poi.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price !== undefined) {
                    calc_tva = ((parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price)));
                    tva += calc_tva;
                    if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: poi.custom_product.title,
                            price: {
                                cost: tva_price,
                                adjustment: poi.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = poi.prices.find((price) => price.master_price);
                    if (tmp_price !== undefined) {
                        if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: poi.custom_product.title,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (!poi.is_cancellable) {
                    has_not_cancellable_items++;
                }
                if (!isProductPackaged({ product: poi, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    let price = getPrice(poi.prices);
                    getMargin(tmp_margin, tmp_surcom, poi.prices, poi.id);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_pois.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_pois.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        assistances.map(assistance => {
            if (assistance.booking_process_state === 'NOTHING' && assistance.booking_status !== null && assistance.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if ((assistance.is_available || (assistance.booking_status !== null && assistance.booking_status.status_booking === 'CONFIRMED')) && assistance.is_displayed && !assistance.is_hidden_for_traveler && !assistance.is_optional && (assistance.booking_status === null || (assistance.booking_process_state !== 'CANCEL' && assistance.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                let tva_price = assistance.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price !== undefined) {
                    calc_tva = parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    tva += calc_tva;
                    if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: t('cart-material.assistance'),
                            price: {
                                cost: tva_price,
                                adjustment: assistance.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = assistance.prices.find((price) => price.master_price);
                    if (tmp_price !== undefined) {
                        if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t('cart-material.assistance'),
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (!assistance.is_cancellable) {
                    has_not_cancellable_items++;
                }
                if (!isProductPackaged({ product: assistance, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    let price = getPrice(assistance.prices);
                    getMargin(tmp_margin, tmp_surcom, assistance.prices, assistance.id);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_assistances.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_assistances.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        transfers.map(transfer => {
            if (transfer.booking_process_state === 'NOTHING' && transfer.booking_status !== null && transfer.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if ((transfer.is_available || (transfer.booking_status !== null && transfer.booking_status.status_booking === 'CONFIRMED')) && transfer.is_displayed && !transfer.is_hidden_for_traveler && !transfer.is_optional && (transfer.booking_status === null || (transfer.booking_process_state !== 'CANCEL' && transfer.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                let tva_price = transfer.prices.find((price) => price.is_tva);
                let calc_tva = 0;
                if (tva_price !== undefined) {
                    calc_tva = (parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price));
                    tva += calc_tva;
                    if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                        modified_product.push({
                            label: transfer.is_custom ? transfer.custom_product.title : transfer.name,
                            price: {
                                cost: tva_price,
                                adjustment: transfer.prices.find((price) => price.master_price),
                                currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                            }
                        });
                    }
                } else {
                    let tmp_price = transfer.prices.find((price) => price.master_price);
                    if (tmp_price !== undefined) {
                        if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: transfer.is_custom ? transfer.custom_product.title : transfer.name,
                                price: {
                                    cost: tmp_price,
                                    adjustment: tmp_price,
                                    currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                }
                            });
                        }
                    }
                }
                if (!transfer.is_cancellable) {
                    has_not_cancellable_items++;
                }
                if (!isProductPackaged({ product: transfer, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    let price = getPrice(transfer.prices);
                    getMargin(tmp_margin, tmp_surcom, transfer.prices, transfer.id);
                    let matched = false;
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_transfers.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += price.cost;
                        }
                    });
                    if (!matched) {
                        total_transfers.push({
                            cost: price.cost,
                            currency: price.currency
                        });
                    }
                }
            }
        });
        flights.map(flight => {
            if (flight.booking_process_state === 'NOTHING' && flight.booking_status !== null && flight.booking_status.status_booking === 'CONFIRMED') {
                has_booked_items++;
            }
            if ((flight.is_available || (flight.booking_status !== null && flight.booking_status.status_booking === 'CONFIRMED')) && flight.is_displayed && !flight.is_hidden_for_traveler && !flight.is_optional && (flight.booking_status === null || (flight.booking_process_state !== 'CANCEL' && flight.booking_status.status_booking !== 'CANCELLED'))) {
                nb_items++;
                if (!isProductPackaged({ product: flight, stackInfos: trip?.stack_info ?? null }) && !custom_cost) {
                    let master_price = flight.prices.find((price) => price.master_price);
                    flight_taxes += getTaxesPrice(flight.taxes) * (master_price !== undefined ? parseFloat(master_price.factor_used) : 1);
                    let price = getPrice(flight.prices);
                    let tva_price = flight.prices.find((price) => price.is_tva);
                    if (tva_price !== undefined) {
                        tva += parseFloat(tva_price.selling_price) - parseFloat(tva_price.purchase_price);
                    }
                    getMargin(tmp_margin, tmp_surcom, flight.prices, flight.id);
                    let matched = false;
                    const flightPrice = getFlightSellingPrice(flight);
                    total_cost.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += flightPrice[0]?.cost ?? 0;
                        }
                    });
                    if (!matched) {
                        total_cost.push({
                            cost: flightPrice[0]?.cost ?? 0,
                            currency: price.currency
                        });
                    }
                    matched = false;
                    total_flights.map(currency_cost => {
                        if (currency_cost.currency.id === price.currency.id) {
                            matched = true;
                            currency_cost.cost += flightPrice[0]?.cost ?? 0;
                        }
                    });
                    if (!matched) {
                        total_flights.push({
                            cost: flightPrice[0]?.cost ?? 0,
                            currency: price.currency
                        });
                    }
                    if (tva_price !== undefined) {
                        if ((moment.utc(tva_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                            modified_product.push({
                                label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                price: {
                                    cost: tva_price,
                                    adjustment: flight.prices.find((price) => price.master_price),
                                    currency: currency_list.find(currency => currency.id === tva_price.selling_currency)
                                }
                            });
                        }
                    } else {
                        let tmp_price = flight.prices.find((price) => price.master_price);
                        if (tmp_price !== undefined) {
                            if ((moment.utc(tmp_price.price_variation_date).isAfter(moment.utc(status_contract_confirmed_date)))) {
                                modified_product.push({
                                    label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                                    price: {
                                        cost: tmp_price,
                                        adjustment: tmp_price,
                                        currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                                    }
                                });
                            }
                        }
                    }
                    //if (moment.utc(flight.created_date).isAfter(moment.utc(status_contract_confirmed_date))) {
                    //let tmp_price = flight.prices[GetPricesId(flight.prices, user.client, user, true)];
                    //new_product.push({
                    //label: t("global.flight") + " " + (flight.outbounds.length === 1 ? t('global.one_way') : t('global.multi_destination')),
                    //price: {
                    //cost: parseFloat(tmp_price.purchase_price) + getTaxesPrice(flight.taxes) + option_cost,
                    //currency: currency_list.find(currency => currency.id === tmp_price.selling_currency)
                    //}
                    //});
                    //}
                }
            }
        });
        if (hasStackedItems && trip.prices_stack_product !== null && trip.prices_stack_product.length > 0 && trip.prices_flight.length === 0 && trip.prices_terrestrial.length === 0 && !custom_cost) {
            let price = getPrice(trip.prices_stack_product);
            getMargin(tmp_margin, tmp_surcom, trip.prices_stack_product);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency.id === price.currency.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
        }
        let cost = 0;
        total_cost.map((currency_cost => {
            cost += parseFloat(currency_cost.cost);
        }));

        if (all_data !== null && all_data.payments_done !== undefined && all_data.payments_done.length !== 0) {
            all_data.payments_done.map((payment) => {
                payment_done += parseFloat(payment.amount);
            });
        }
        advance_payment = trip.expected_deposit !== null ? trip.expected_deposit : 0;

        total_cost.map(currency_cost => currency_cost.cost = (currency_cost.cost).toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
        tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
        if (tmp_margin.value !== 0) {
            if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code)) {
                tmp_margin.percent = parseFloat(((tmp_margin.value / cost) * 100).toFixed(2));
            } else if (tmp_margin.markup) {
                tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
            } else {
                tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
            }
        }
        dispatch({ type: 'CART_GLOBAL_MARGIN_TOTAL', payload: { margin: tmp_margin.percent, margin_value: tmp_margin.value, tva_value: tva, accommodations: total_accommodations, cars: total_cars, pois: total_pois, transfers: total_transfers, flights: total_flights, manual_products: total_manual_products, assistances: total_assistances, has_not_cancellable_items: has_not_cancellable_items } });
        tmp_surcom.static = tmp_surcom.value;
        tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
        tmp_surcom.percent = parseFloat(((tmp_surcom.value / tmp_surcom.selling) * 100));
        setTotalCartCost(cost);
        setMargin(tmp_margin);
        setSurcom(tmp_surcom);
        setTotalCost(total_cost);
        setFlightTaxes(flight_taxes);
        setHasBookedItems(has_booked_items);
        setNbItems(nb_items);
        setAdvancePayment(advance_payment);
        setNewAdvancePayment(advance_payment);
        setNewProduct(new_product);
        setModifiedProduct(modified_product);
        setPaymentDone(payment_done);
        setTripTva(tva);
    }, [flights, cars, accommodations, pois, transfers, manual_products, trip, hasStackedItems, getTaxesPrice]);
    useEffect(() => {
        if (total_cart_cost !== null && all_data !== null && all_data.agreed_price !== null) {
            let diff = totalCartCost.toFixed(2) - parseFloat(all_data.agreed_price);
            setDiffPrice(diff);
        }
    }, [totalCartCost, total_cart_cost, all_data]);
    useEffect(() => {
        if (['cercledesvoyages', 'continentsinsolites', 'connections', 'terreslointaines', 'travelmuz'].includes(quotation_code) && !see_margin) {
            onToggleMargin();
        }
    }, []);
    useEffect(() => {
        let tmp_to_book = getToBookProducts();
        if (tmp_to_book.length === to_book.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [to_book]);
    console.log('totalCost:', totalCost);

    return (
        <>
            <CartConstructionProductsHeader
                page="booking"
                stickedActions={(actions) => stickedActions(
                    <Stack
                        direction="row"
                        spacing={1.5}
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        {
                            step === 3 &&
                            <FormControl className={ classes.cancelAllWrapper }>
                                <Button size="small" variant={ 'contained' } className={ classes.orangeButton } onClick={ downloadQuotation } disabled={ spinDownload }>{ t("cart.download_pdf") }</Button>
                                { spinDownload && <CircularProgress size={ 24 } className={ classes.cancelAllRunning }/> }
                            </FormControl>
                        }
                        {actions}
                        <CartPriceVariationDialog openPriceVariation={openPriceVariation} handlePriceVariation={handlePriceVariation} diffPrice={diffPrice} newProduct={newProduct}/>
                        <Dialog open={ cancelAllModal } maxWidth={ 'md' } onClose={ onToggleCancelAll }>
                            <DialogTitle>{ t('cart-material.cancel-all-booking') }</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{ t('cart-material.cancel-all-booking-text') }</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button size="small" className={ classes.cancelAllCancel } onClick={ onToggleCancelAll }>{ t('global.no') }</Button>
                                <Button size="small" className={ classes.orangeButton } onClick={ onCancelAllBooking }>{ t('global.yes') }</Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                )}
                cartDisplay="product"
                collapse={false}
                onChangeCollapse={() => null}
                onToggleCartDisplay={() => null}
                enabledButtons={['traveler-website']}
                fullHeight
                alwaysSticky
                disableProductsFilters
            />
            {
                step === 3 &&
                <Stack direction="row" spacing={2} sx={{ paddingTop: 1 }}>
                    {
                        !['cercledesvoyages', 'verdie', 'terreslointaines', 'travelmuz'].includes(quotation_code) &&
                        <FormControl className={ classes.selector } variant={ 'outlined' } size="small">
                            <Select value={ quotation_target } onChange={ onQuotationTargetChange }>
                                {
                                    user.client_full.type !== 2 && (
                                        <MenuItem value={ 'TO' }>{ t('cart-material.quotation-to-price') }</MenuItem>
                                    )
                                }
                                <MenuItem value={ 'COMMISSIONED' }>{ t('cart-material.quotation-commissioned-price') }</MenuItem>
                                <MenuItem value={ 'CLIENT' }>{ t('cart-material.quotation-client-price') }</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    {
                        ['continentsinsolites'].includes(quotation_code) &&
                        <FormControl className={ classes.selector } variant={ 'outlined' } size="small">
                            <InputLabel id={ 'quotation-type' }>{ t('cart-material.quotation-type-title') }</InputLabel>
                            <Select labelId={ 'quotation-type' } label={ t('cart-material.quotation-type-title') } value={ quotation_type } onChange={ onQuotationTypeChange }>
                                <MenuItem value={ 'DEFAULT' }>{ t('cart-material.quotation-type-default') }</MenuItem>
                                <MenuItem value={ 'STEP' }>{ t('cart-material.quotation-type-step') }</MenuItem>
                                <MenuItem value={ 'DAY' }>{ t('cart-material.quotation-type-day') }</MenuItem>
                            </Select>
                        </FormControl>
                    }
                    {
                            !['cercledesvoyages', 'verdie', 'terreslointaines', 'travelmuz'].includes(quotation_code) &&
                        <>
                            <FormControlLabel className={ classes.checkBoxLabel } control={ <Checkbox icon={ <CheckBoxOutlineBlank/> } checkedIcon={ <CheckBoxFilled className={ classes.checked }/> } checked={ quotation_category_price_detail } onChange={ onCategoryPriceDetailToggle }/>} label={ t('cart-material.quotation-category-price-detail') }/>
                            <FormControlLabel className={ classes.checkBoxLabel } control={ <Checkbox icon={ <CheckBoxOutlineBlank/> } checkedIcon={ <CheckBoxFilled className={ classes.checked }/> } checked={ quotation_product_price_detail } onChange={ onProductPriceDetailToggle }/>} label={ t('cart-material.quotation-product-price-detail') }/>
                        </>
                    }
                    <FormControlLabel className={ classes.checkBoxLabel } control={ <Checkbox icon={ <CheckBoxOutlineBlank/> } checkedIcon={ <CheckBoxFilled className={ classes.checked }/> } checked={ quotation_practical_information } onChange={ onPracticalInformationToggle }/>} label={ quotation_code !== 'cercledesvoyages' ? t('cart-material.quotation-practical-information') : t('cart-material.quotation-formalities')}/>
                    <FormControlLabel className={ classes.checkBoxLabel } control={ <Checkbox icon={ <CheckBoxOutlineBlank/> } checkedIcon={ <CheckBoxFilled className={ classes.checked }/> } checked={ quotation_without_prices } onChange={ onGenerateWithoutPricesToggle }/>} label={ t('cart-material.quotation-generate-without-prices') }/>
                    {
                            !['cercledesvoyages', 'terreslointaines', 'travelmuz'].includes(quotation_code) &&
                        <FormControlLabel className={ classes.checkBoxLabel } control={ <Checkbox icon={ <CheckBoxOutlineBlank/> } checkedIcon={ <CheckBoxFilled className={ classes.checked }/> } checked={ quotation_cgv } onChange={ onCgvToggle }/>} label={ t('cart-material.quotation-cgv') }/>
                    }
                    {
                        (
                            window.location.origin === 'https://dev.facilitatrip.com' ||
                            window.location.origin === 'https://demo.facilitatrip.com' ||
                            window.location.hostname.endsWith('.fr') ||
                            quotation_code === "volonline" ||
                            quotation_code === "gattinoni"
                        ) &&
                        <FormControl size="small" className={ classes.selector } variant={ 'outlined' }>
                            <Select value={ quotation_target_language } onChange={ onQuotationTargetLanguageChange }>
                                { locales.map(locale => <MenuItem key={ `quotation-target-language-${locale.language_code}` } value={ locale.language_code }>{ locale.full_name }</MenuItem>) }
                            </Select>
                        </FormControl>
                    }
                </Stack>
            }
        </>
    );
};

export default withRouter(CartCard);
